import { Component, OnInit } from '@angular/core';
import { Meta,Title } from "@angular/platform-browser";
import {Router} from '@angular/router';
import {RessourceService} from '../../core/services/ressource.service';
import {Ressource} from '../../shared/components/models/ressource';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-ressources',
  templateUrl: './ressources.component.html',
  styleUrls: ['./ressources.component.css']
})
export class RessourcesComponent implements OnInit {
  ressources: Ressource[];
  loading: boolean = false;
  imageUrl = '';
  constructor(private router: Router,
    private meta: Meta,
    private title: Title,
              private ressourceService: RessourceService) {
    this.imageUrl = environment.ressourceImageUri;
  }

  ngOnInit() {
    this.loadRessources();
    this.title.setTitle("Mineral Resources | Mining Products | MineInsiders");
    this.meta.updateTag({ 
      name: 'description',
      content: 'Need information on Mineral Resources? Check out MineInsiders, a digital platform dedicated to mining, to get mineral resource insights. Click to Learn More!'
    });
    this.meta.updateTag({ 
      name: 'og:title',
      content: 'Mineral Resources | Mining Products | MineInsiders'
    });
    this.meta.updateTag({ 
      name: 'og:description',
      content: 'Need information on Mineral Resources? Check out MineInsiders, a digital platform dedicated to mining, to get mineral resource insights. Click to Learn More!'
    });
  }
  goToRessourceDetails(row) {
    this.router.navigate(['/ressource-details'],{ queryParams: {ressource: row.id}});
  }
  loadRessources() {
    this.loading = true;
    this.ressourceService.findAll().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.ressources = ret['response'];
        // console.log('ressources', this.ressources);
        this.loading = false;
      } else {
        this.loading = false;
        this.router.navigate(['/error']);
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
      this.router.navigate(['/error']);
    });
  }
}
