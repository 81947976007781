import { Component, OnInit } from '@angular/core';
import { Meta,Title } from "@angular/platform-browser";
import {Router} from '@angular/router';
import { Documents } from 'src/app/shared/components/models/documents';
import { DocumentService } from 'src/app/core/services/document.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {
  guideList: Documents[];
  documentImageUri = '';
  loading: boolean = false;
  p: number = 1;
  constructor(private router: Router,
              private meta: Meta,
              private title: Title,
              private documentService: DocumentService) {
    this.documentImageUri = environment.documentImageUri;
  }

  ngOnInit() {
    this.loadGuideList();
    window.scrollTo(0, 0);
    this.title.setTitle("Mining Guide | Legal Mining Permits | MineInsiders");
    this.meta.updateTag({ 
      name: 'description',
      content: 'Planning to start mining? Check out MineInsiders, a digital mining platform, and learn the mining guides and guidance for mining projects. Click to Learn More!'
    });
    this.meta.updateTag({ 
      name: 'og:title',
      content: 'Mining Guide | Legal Mining Permits | MineInsiders'
    });
    this.meta.updateTag({ 
      name: 'og:description',
      content: 'Planning to start mining? Check out MineInsiders, a digital mining platform, and learn the mining guides and guidance for mining projects. Click to Learn More!'
    });
  }
  showDetails(guide: Documents) {
    this.router.navigate(['/guide-detail'] ,{ queryParams: {document: guide.id} });
  }
  loadGuideList() {
    this.loading = true;
    this.documentService.findDocumentByGuide().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.guideList = ret['response'];
       // console.log('list doc guide ', this.guideList);
        this.loading = false;
      } else {
        // console.log('erreur survenu');
        this.loading = false;
      }
    }, error => {
      // console.log('erreur server');
      this.loading = false;
    });
  }
}
