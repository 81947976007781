<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>
<ngx-loading-bar color="#fff"></ngx-loading-bar>
<section id="team" class="wow fadeInUp">
  <div class="container">
    <div class="section-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/accueil">Accueil</a></li>
          <li class="breadcrumb-item active" aria-current="page">Opportunités de stages dans les mines de l'UEMOA</li>
        </ol>
      </nav>
    </div>
    <div class="section-header">
      <mat-card>
        <div class="row">
          <div class="col-md-8 col-lg-8 col-sm-8 col-12">
            <p style="color: #929197;font-size: large;text-align: center;">Les offres de stages dans l'espace UEMOA</p>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="row" style="min-height: 400px">
      <div class="col-sm-12 col-lg-12 col-md-12 col-xl-12 col-12">
        <div class="card card-body" style="margin-bottom: 10px" *ngFor="let stage of stageList | paginate: { itemsPerPage: 4, currentPage: p }">
          <div class="row" (click)="showStagesDetails(stage)" style="cursor: pointer">
            <div class="col-sm-12 col-lg-4 col-md-4 col-xl-4 col-12">
              <div class="text-center">
                <a href="javascript:void(0)">
                  <img class="img-thumbnail" alt="Main Logo" style="height: 100px;width: 50%" src="assets/img/logo/logo.png">
                </a>
              </div>
            </div>
            <div class="col-sm-12 col-lg-8 col-md-8 col-xl-8 col-12">
              <div>
                <a href="javascript:void(0)" style="color: black">
                  <h4 style="font-size: 18px;color: #007cdc">{{stage.nom}}</h4>
                </a>
                <p [innerHtml]="stage.description | slice:0:500"><span>...</span>
                <div class="row">
                  <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                    <span style="color: #007de2"><i class="fa fa-calendar"></i> {{ stage.dateCreation | date: 'dd/MM/yyyy' }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
            <pagination-controls (pageChange)="p = $event"
                        directionLinks="true"
                        autoHide="true"
                        responsive="true"
                        previousLabel="Précédent"
                        nextLabel="Suivant"
                        screenReaderPaginationLabel="Pagination"
                        screenReaderPageLabel="page"
                        screenReaderCurrentLabel="Vous êtes dejà sur la page">
            </pagination-controls>
        <div class="col-md-12 col-lg-12 col-12 col-sm-12" *ngIf="stageList?.length==0" align="center">
          Aucune offre trouvée
        </div>
      </div>

    </div>
  </div>

</section>
<!-- #team -->



