<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>

<section id="portfolio" class="wow fadeInUp">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/forum">Forum professionnels</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{forum?.nom}}</li>
      </ol>
    </nav>
  </div>

  <div class="container">
    <div class="row">
          <div class="col-lg-12 col-12 col-sm-12 col-md-12 col-xl-12" style="margin-left: 20px;margin-right: 20px">
            <!-- the actual blog post: title/author/date/content -->
            <p class="lead"><i class="fa fa-user"></i> Par <a [href]="'#/detail-cadre-mines?minerId='+ forum?.minier?.id"> {{forum?.minier?.prenom}} {{forum?.minier?.nom}}</a>
            </p>
            <hr>
            <p><i class="fa fa-calendar"></i> Publié : {{forum?.dateCreation | date : 'medium'}}</p>
            <p><i class="fa fa-comment"></i> {{forum?.commentCount}} Commentaires</p>
            <hr>
            <div class="row">
              <div class="col-lg-6 col-12 col-sm-12 col-md-6 col-xl-6">
                <img [src]="documentImageUri+forum?.fileUploadName" style="width: 100%;" alt="Detail Cadre Mines">
              </div>
            </div>
            <hr>
            <h3>{{forum?.nom}}</h3>
            <p style="width: 50%" [innerHtml]="forum?.description"></p>
            <br/>
            <hr>
            <!-- the comment box -->
            <div class="card card-body bg-light" *ngIf="currentUser?.id">
              <h4 style="color: #027bda"><i class="fa fa-paper-plane-o"></i> Laisser un commentaire:</h4>
              <form #commentForm="ngForm" name="form">
                <div class="form-group">
                  <textarea id="comment" name="comment" [(ngModel)]="commentaire.message" class="form-control" rows="5" required></textarea>
                </div>
                <button type="submit" class="btn btn-primary" [disabled]="!commentForm.form.valid"  (click)="saveCommentaire()"><i class="fa fa-reply"></i> Commenter</button>
              </form>
            </div>
            <hr>
            <!-- the comments -->
            <div *ngFor="let com of commentaires">
              <p><i class="fa fa-comment" style="color: #007bff"></i> <span style="font-weight: bold"> <a href="javascript:void(0)" style="font-weight: bold;color: #007bff" (click)="showDetailsMiniers(forum.minier)">  {{com.minier?.prenom}} {{com.minier?.nom}} </a> <span style="color: black">( {{com.minier?.specialite}} )</span> :</span>
                <span> {{com.dateCreation | date: 'medium'}}</span>
              </p>
              <p style="margin-left: 50px">{{com.message}}</p>
            </div>
            <div>
              <p></p>
            </div>
          </div>
        </div>
  </div>
</section>
<!-- #portfolio -->


