
<!-- #country list -->
<section id="sites" class="wow fadeInUp" style="min-height: 500px;margin-top: 40px;margin-bottom: 20px">
  <div class="container">
    <div class="section-header">
      <h2>Entreprises Minières de l'espace  l'UEMOA</h2>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 map-box" (click)="goToEntrepriseList(223)">
        <img mat-card-image src="assets/img/pays/flag/mali.svg" class="img-thumbnail" alt="Entreprises Flag Mali">
        <p style="text-align: center;font-weight: bold;font-size: 14px">Mali ({{maliEntreprises?.length}})</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 map-box" (click)="goToEntrepriseList(226)">
        <img mat-card-image src="assets/img/pays/flag/burkina.png" class="img-thumbnail" alt="Flag Burkina">
        <p style="text-align: center;font-weight: bold;font-size: 14px">Burkina Faso ({{burkinaEntreprises?.length}})</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 map-box" (click)="goToEntrepriseList(225)">
        <img mat-card-image src="assets/img/pays/flag/ivoire.svg" class="img-thumbnail" alt="Flag Ivoire">
        <p style="text-align: center;font-weight: bold;font-size: 14px">Côte d'ivoire ({{ivoireEntreprises?.length}})</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 map-box" (click)="goToEntrepriseList(221)">
        <img mat-card-image src="assets/img/pays/flag/senegal.svg" class="img-thumbnail" alt="Flag Sénégal">
        <p style="text-align: center;font-weight: bold;font-size: 14px">Sénégal ({{senegalEntreprises?.length}})</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      </div>
    </div>
    <div class="row" style="margin-top: 40px">
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 map-box" (click)="goToEntrepriseList(227)">
        <img mat-card-image src="assets/img/pays/flag/niger.png" class="img-thumbnail" alt="Flag Niger">
        <p style="text-align: center;font-weight: bold;font-size: 14px">Niger ({{nigerEntreprises?.length}})</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 map-box" (click)="goToEntrepriseList(228)">
        <img mat-card-image src="assets/img/pays/flag/togo.png" class="img-thumbnail" alt="Flag Togo">
        <p style="text-align: center;font-weight: bold;font-size: 14px">Togo ({{togoEntreprises?.length}})</p>
      </div>
      <div class="col-l-2 col-md-2 col-sm-2 col-2 map-box" (click)="goToEntrepriseList(229)">
        <img mat-card-image src="assets/img/pays/flag/benin.jpg" class="img-thumbnail" alt="Flag Benin">
        <p style="text-align: center;font-weight: bold;font-size: 14px">Benin ({{beninEntreprises?.length}})</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2 map-box" (click)="goToEntrepriseList(245)">
        <img mat-card-image src="assets/img/pays/flag/bissau.png" class="img-thumbnail" alt="Flag Bissau">
        <p style="text-align: center;font-weight: bold;font-size: 14px">Guinée Bissau ({{bissauEntreprises?.length}})</p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
      </div>
    </div>
  </div>
</section>
<!-- #country list-->

