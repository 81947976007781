<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '5px',fullScreenBackdrop:true }"></ngx-loading>
<ngx-loading-bar color="#fff"></ngx-loading-bar>
<section id="team" class="wow fadeInUp" style="margin-bottom: 20px">

  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)" (click)="showList()">Liste Offres</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{job?.nom}}</li>
      </ol>
    </nav>
  </div>

  <div class="container-fluid">
    <!-- Main container -->
    <div class="container">
      <div class="row" style="padding: 10px">
        <div class="col-12 col-sm-12 col-md-8 col-lg-8">
          <mat-card>
            <app-pub-b></app-pub-b>
          </mat-card>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <mat-card style="background-color: #007cdc;padding-top: 10px;color: #FFFFFF;margin: 0">
            <div class="row">
              <div class="col-12 col-sm-12 col-12">
                <h3 style="font-size: 14px">Vous êtes miniers? inscrivez vous gratuitement pour gagner en visibilité!</h3>
              </div>
              <div class="col-12 col-sm-12 col-12">
                <button class="btn btn-grey" routerLink="/register">Je m'inscris gratuitement</button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <!--details documents-->
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-12 col-12">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card card-body bg-light" style="margin-bottom: 10px">
              <div class="row">
                <div class="col-sm-12 col-lg-12 col-md-12 col-xl-12 col-12">
                    <a href="javascript:void(0)" style="color: black">
                      <h4 style="font-size: x-large;color: #007cdc">{{job?.nom}}</h4>
                    </a>
                    <p [innerHtml]="job?.description"></p>
                    <div class="row">
                      <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                        <span style="font-weight: bolder">Date Publication : </span> <span style="color: #007de2"><i class="fa fa-calendar"></i>  {{ job?.datePublication | date: 'dd/MM/yyyy'}}</span>
                      </div>
                      <div class="col-sm-6 col-lg-6 col-md-6 col-6 col-xl-6">
                        <span style="font-weight: bolder">Date Limite : </span> <span style="color: #007de2"><i class="fa fa-calendar"></i>  {{ job?.dateLimite | date: 'dd/MM/yyyy'}}</span>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <mat-card style="margin-bottom: 10px">
              <app-pub-e></app-pub-e>
            </mat-card>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row" style="margin-bottom: 10px">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <mat-card *ngIf="otherJobs.length>0" style="height: 460px;margin-bottom: 10px">
                  <img mat-card-image class="img-article" src={{opportuniteImageUri+otherJobs[0]?.imageName}} alt="Image Article">
                  <mat-card-content>
                  </mat-card-content>
                  <mat-card-actions class="action-buttons">
                    <mat-card-title style="font-size: 18px" class="title">{{otherJobs[0]?.nom}}</mat-card-title>
                    <a mat-button color="primary" [href]="'#/Jobs-detail?opportunite=' + otherJobs[0]?.id" ><mat-icon>visibility</mat-icon> En savoir plus</a>
                  </mat-card-actions>
                </mat-card>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <mat-card *ngIf="otherJobs.length>0" style="height: 460px;margin-bottom: 10px">
                  <img mat-card-image class="img-article" src={{opportuniteImageUri+otherJobs[1]?.imageName}} alt="Image Article">
                  <mat-card-content>
                  </mat-card-content>
                  <mat-card-actions class="action-buttons">
                    <mat-card-title style="font-size: 18px" class="title">{{otherJobs[1]?.nom}}</mat-card-title>
                    <a mat-button color="primary" [href]="'#/Jobs-detail?opportunite=' + otherJobs[1]?.id" ><mat-icon>visibility</mat-icon> En savoir plus</a>
                  </mat-card-actions>
                </mat-card>
              </div>
            </div>
          </div>
          <br>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-12">
              <mat-card style="margin-bottom: 10px">
               <app-pub-c></app-pub-c>
              </mat-card>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-12">
              <mat-card style="margin-bottom: 10px">
                <div class="col-sm-12 col-12">
                  <button style="background-color: #047bdd;border-color: #007ce2;" class="btn btn-primary" routerLink="/guides">Consulter nos guides
                  </button>
                </div>
              </mat-card>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
              <mat-card style="margin-bottom: 10px">
                <app-pub-d></app-pub-d>
              </mat-card>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
              <mat-card style="margin-bottom: 10px">
                <div class="section-header">
                  <h2> Articles recents</h2>
                </div>
                <app-article-recents></app-article-recents>
              </mat-card>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <!-- Qui sommes nous -->
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <app-qui-sommes-nous></app-qui-sommes-nous>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <br>
                  <mat-card style="margin-bottom: 10px" *ngIf="false">
                    Pub Google
                  </mat-card>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <br>
                  <mat-card style="margin-bottom: 10px" *ngIf="false">
                    Pub Google
                  </mat-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End :details profiles -->

    </div> <!--End of Container-->
  </div>
</section>
<!-- #team -->

