<section id="sites" class="wow fadeInUp" style="min-height: 500px;margin-top: 40px;margin-bottom: 20px">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Acceuil</a></li>
        <li class="breadcrumb-item"><a href="javascript:void (0)" (click)="backClicked()">Entreprises</a></li>
      </ol>
    </nav>
  </div>
  <div class="container">
    <div class="section-header">
      <h2>{{entreprise?.nom}}</h2>
    </div>
    <div class="row">
      <mat-card style="width: 100%">
        <img mat-card-image [src]="imageUrl+entreprise?.logoImageName" style="height: 120px;width: 120px;float: left;margin-left: 10px" class="img-thumbnail" alt="Logo Image Name">
        <span style="float: right;margin-right: 20px"> <span style="font-weight: bold">Adresse : </span>{{entreprise?.adresse}}</span>
      </mat-card>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="section-header">
              <h2>Informations</h2>
            </div>
            <p [innerHtml]="entreprise?.information"></p>
            <mat-divider></mat-divider>
            <br>
          </div>
          <br>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="section-header">
              <h2>Côte ou devise boursière</h2>
            </div>
            <p>{{entreprise?.coteBoursiere}}</p>
            <mat-divider></mat-divider>
            <br>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="section-header">
              <h2>Projets de développement</h2>
            </div>
            <p [innerHtml]="entreprise?.projet"></p>
            <mat-divider></mat-divider>
            <br>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="section-header">
              <h2>Lien site Web</h2>
            </div>
            <span style="margin-left: 10px;font-weight: bold">Site Web :</span> <a [href]="entreprise?.siteLink"> {{entreprise?.siteLink}}</a>
            <mat-divider></mat-divider>
            <br>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="section-header">
              <h2>Ressources minères exploitées</h2>
            </div>
            <div *ngIf="entreprise?.ressources">
              <div style="font-weight: bold;color: #1a68ff" *ngFor="let res of entreprise?.ressources">
                {{res.nom}}
              </div>
            </div>
            <mat-divider></mat-divider>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
