// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*apiUrl: 'http://localhost:8084/mineinsiders-service',
  imageUri: 'http://localhost:8084/mineinsiders-service/articleImage/',
  profilImageUri: 'http://localhost:8084/mineinsiders-service/profilImage/',
  documentImageUri: 'http://localhost:8084/mineinsiders-service/documentImage/',
  documentFileUri: 'http://localhost:8084/mineinsiders-service/docFile/',
  partenaireImageUri: 'http://localhost:8084/mineinsiders-service/partenaireImage/',
  sliderImageUri: 'http://localhost:8084/mineinsiders-service/sliderImage/',
  newsImageUri: 'http://localhost:8084/mineinsiders-service/newsImage/',
  cvFileUri: 'http://localhost:8084/mineinsiders-service/cvFile/',
  entrepriseImageUri: 'http://localhost:8084/mineinsiders-service/entrepriseImage/',
  ressourceImageUri: 'http://localhost:8084/mineinsiders-service/ressourceImage/',
  opportuniteImageUri: 'http://localhost:8084/mineinsiders-service/opportuniteImage/',
  publiciteImageUri: 'http://localhost:8084/mineinsiders-service/publiciteImage/',
  temoignageImageUri: 'http://localhost:8084/mineinsiders-service/temoignageImage/',
  eventsImageUri: 'http://localhost:8084/mineinsiders-service/eventImage/',
  pochetteImageUrl: 'http://localhost:8084/mineinsiders-service/pochetteDocService/',*/
apiUrl: 'https://mineinsiders.com/mineinsiders-service',
imageUri: 'https://mineinsiders.com/mineinsiders-service/articleImage/',
profilImageUri: 'https://mineinsiders.com/mineinsiders-service/profilImage/',
documentImageUri: 'https://mineinsiders.com/mineinsiders-service/documentImage/',
documentFileUri: 'https://mineinsiders.com/mineinsiders-service/docFile/',
partenaireImageUri: 'https://mineinsiders.com/mineinsiders-service/partenaireImage/',
sliderImageUri: 'https://mineinsiders.com/mineinsiders-service/sliderImage/',
newsImageUri: 'https://mineinsiders.com/mineinsiders-service/newsImage/',
cvFileUri: 'https://mineinsiders.com/mineinsiders-service/cvFile/',
entrepriseImageUri: 'https://mineinsiders.com/mineinsiders-service/entrepriseImage/',
ressourceImageUri: 'https://mineinsiders.com/mineinsiders-service/ressourceImage/',
opportuniteImageUri: 'https://mineinsiders.com/mineinsiders-service/opportuniteImage/',
publiciteImageUri: 'https://mineinsiders.com/mineinsiders-service/publiciteImage/',
temoignageImageUri: 'https://mineinsiders.com/mineinsiders-service/temoignageImage/',
eventsImageUri: 'https://mineinsiders.com/mineinsiders-service/eventImage/',
pochetteImageUrl: 'https://mineinsiders.com/mineinsiders-service/pochetteDocService/'

/* apiUrl: 'http://192.168.3.20:8080/mineinsiders-service',
imageUri: 'http://192.168.3.20:8080/mineinsiders-service/articleImage/',
profilImageUri: 'http://192.168.3.20:8080/mineinsiders-service/profilImage/',
documentImageUri: 'http://192.168.3.20:8080/mineinsiders-service/documentImage/',
documentFileUri: 'http://192.168.3.20:8080/mineinsiders-service/docFile/',
partenaireImageUri: 'http://192.168.3.20:8080/mineinsiders-service/partenaireImage/',
sliderImageUri: 'http://192.168.3.20:8080/mineinsiders-service/sliderImage/',
newsImageUri: 'http://192.168.3.20:8080/mineinsiders-service/newsImage/',
cvFileUri: 'http://192.168.3.20:8080/mineinsiders-service/cvFile/',
entrepriseImageUri: 'http://192.168.3.20:8080/mineinsiders-service/entrepriseImage/',
ressourceImageUri: 'http://192.168.3.20:8080/mineinsiders-service/ressourceImage/',
opportuniteImageUri: 'http://192.168.3.20:8080/mineinsiders-service/opportuniteImage/',
publiciteImageUri: 'http://192.168.3.20:8080/mineinsiders-service/publiciteImage/',
temoignageImageUri: 'http://192.168.3.20:8080/mineinsiders-service/temoignageImage/',
eventsImageUri: 'http://192.168.3.20:8080/mineinsiders-service/eventImage/'*/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
