import { Component, OnInit,AfterViewInit } from '@angular/core';
import { Meta,Title } from "@angular/platform-browser";
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {environment} from '../../../environments/environment';
import {Opportunite} from '../../shared/components/models/opportunite';
import {NewsService} from '../../core/services/news.service';
import {OpportuniteService} from '../../core/services/opportunite.service';
import {News} from '../../shared/components/models/news';
import {Events} from '../../shared/components/models/events';
import {EventsService} from '../../core/services/events.service';

@Component({
  selector: 'app-mining-events',
  templateUrl: './mining-events.component.html',
  styleUrls: ['./mining-events.component.scss']
})
export class MiningEventsComponent implements OnInit,AfterViewInit {
  list: boolean = true;
  detail: boolean = false;
  loading: boolean = false;
  newses: News[] = [];
  jobList: Opportunite[] = [];
  news: News;
  events: Events;
  eventList: Events[];
  newsImageUri = '';
  eventsImageUri = '';
  p: number = 1;
  otherEvents: Events[] = [];
  constructor(private router: Router,
              private meta: Meta,
              private title: Title,
              private route: ActivatedRoute,
              private newsService: NewsService,
              private loadingBar: LoadingBarService,
              private opportuniteService: OpportuniteService,
              private eventService: EventsService) {
    this.newsImageUri = environment.newsImageUri;
    this.eventsImageUri = environment.eventsImageUri;
  }
  renderExternalScript() {
    const body = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.innerHTML = '{ "@context": "https://schema.org", "@type": "Event", "name": "Symposium Mines Guinea will take place on 15-17 November 2022", "description": "Symposium Mines Guinea (SMG 2022), will take place from 15 – 17 November 2022, in Conakry, Republic of Guinea and the main theme of this edition is “Optimizing linkages with the mining industry to catalyse the social-economic development of Guinea”.", "image": "https://mineinsiders.com/mineinsiders-service/eventImage/5.jpeg", "startDate": "2022-11-15", "endDate": "2022-11-17", "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode", "location": { "@type": "Place", "name": "Symposium Mines Guinea", "eventStatus": "https://schema.org/EventScheduled", "address": { "@type": "PostalAddress", "streetAddress": "", "addressLocality": "CONAKRY", "postalCode": "224", "addressCountry": "GN" } } }';
    script.type = 'application/ld+json';
    script.async = false;
    script.defer = false;
    body.append(script);
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.event) {
        this.loadingBar.start();
        this.eventService.findEventById(params.event).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.events = ret['response'];
            // console.log('event', this.events);
            this.loadingBar.complete();
            this.loadOthersEvents(params.event);
          } else {
            this.loadingBar.complete();
            this.router.navigate(['/error']);
          }
        }, error => {
          console.log('erreur server');
          this.loadingBar.complete();
          this.router.navigate(['/error']);
        });
      } else {
        this.loadEvents();
        this.news = null;
      }
      this.title.setTitle("Mining Events | Mining Networking Events | MineInsiders");
      this.meta.updateTag({ 
        name: 'description',
        content: 'New to Mining? Check out MineInsiders, a digital mining platform, for the latest mining events and insights in the mining industry. Click to Learn More!'
      });
      this.meta.updateTag({ 
        name: 'og:title',
        content: 'Mining Events | Mining Networking Events | MineInsiders'
      });
      this.meta.updateTag({ 
        name: 'og:description',
        content: 'New to Mining? Check out MineInsiders, a digital mining platform, for the latest mining events and insights in the mining industry. Click to Learn More!'
      });
    });
    window.scrollTo(0, 0);
  }
  showDetail(event: Events) {
    this.router.navigate(['/mining-events'] ,{ queryParams: {event: event.id} }).
    then(() => {
      window.location.reload();
    });
  }
  showList() {
    this.events = null;
    this.router.navigate(['/mining-events']);
  }
  loadEvents() {
    this.loadingBar.start();
    this.eventService.findAllEnabledEvents().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.eventList = ret['response'];
        this.loadingBar.complete();
      } else {
        console.log('erreur survenu');
        this.loadingBar.complete();
      }
    }, error => {
      console.log('erreur server');
      this.loadingBar.complete();
    });
  }
  loadNews() {
    this.newsService.findAllNews().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.newses = ret['response'];
      } else {
        console.log('erreur survenu');
      }
    }, error => {
      console.log('erreur server');
    });
  }

  loadLatestJobs() {
    this.opportuniteService.findAllOpportunite().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.jobList = ret['response'];
        console.log('+++++ list Job ', this.jobList);
      } else {
        console.log('erreur survenu');
      }
    }, error => {
      console.log('erreur server');
    });
  }

  loadOthersEvents(eventId) {
    this.eventService. findOthers(eventId).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.otherEvents = ret['response'];
      } else {
        console.log('erreur survenu');
      }
    }, error => {
      console.log('erreur server');
    });
  }
  ngAfterViewInit() {
    this.renderExternalScript();
  }

}
