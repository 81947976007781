import { Component, OnInit } from '@angular/core';
import {NewsService} from '../../core/services/news.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchParam} from '../../shared/components/models/search-param';
import {SearchResult} from '../../shared/components/models/search-result';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  searchParam: SearchParam = new SearchParam();
  searchResult: SearchResult[];
  newsImageUri = '';
  opportuniteImageUri = '';
  eventsImageUri = '';
  p: number = 1;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private newsService: NewsService,
    private loadingBar: LoadingBarService,
  ) {
    this.newsImageUri = environment.newsImageUri;
    this.opportuniteImageUri = environment.opportuniteImageUri;
    this.eventsImageUri = environment.eventsImageUri;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.query) {
        this.loadingBar.start();
        this.searchParam.query = params.query;
        this.newsService.search(this.searchParam).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.searchResult = ret['response'];
            // console.log('searchResult', this.searchResult);
            this.loadingBar.complete();
          } else {
            this.loadingBar.complete();
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loadingBar.complete();
          this.router.navigate(['/error']);
        });
      } else {
      }
    });
  }

  showDetail(item) {
    if (item.type === 'NEWS') {
      this.router.navigate(['/mining-news'] ,{ queryParams: {news: item.id} }).
      then(() => {
      });
    }
    if(item.type === 'JOBS') {
      this.router.navigate(['/jobs-detail'] ,{ queryParams: {opportunite: item.id} }).
      then(() => {
      });
    }
    if(item.type === 'EVENTS') {
      this.router.navigate(['/mining-events'] ,{ queryParams: {event: item.id} }).
      then(() => {
      });
    }
  }

}
