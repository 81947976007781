import { Component, OnInit } from '@angular/core';
import {Publicite} from '../../../shared/components/models/publicite';
import {PubliciteService} from '../../../core/services/publicite.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-pub-c',
  templateUrl: './pub-c.component.html',
  styleUrls: ['./pub-c.component.scss']
})
export class PubCComponent implements OnInit {
  pubC: Publicite;
  publicites: Publicite[] = [];
  publiciteImageUri: string;
  constructor(private publiciteService: PubliciteService) {
    this.publiciteImageUri = environment.publiciteImageUri;
  }

  ngOnInit(): void {
    this.loadPublicite();
  }

  loadPublicite() {
    this.publiciteService.findPubC().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.publicites = ret['response'].map((value,index)=>{
            value['link'] = value['link'].replace('/#', '');
            return value;
        });
        this.pubC = this.publicites.find(p=>p.type==='C');
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  addHashToUrl(url) {
    if(url.includes('#')) return url
    url = url.split('/').map((item) => {
        if (item == 'mineinsiders.com') item = item + '/#'
        return item
    })
    return url.join('/')
}
}
