import { Component, OnInit } from '@angular/core';
import { Meta,Title } from "@angular/platform-browser";
import {Router} from '@angular/router';
import {EntrepriseService} from '../../../core/services/entreprise.service';
import {Entreprise} from '../../../shared/components/models/entreprise';
import {Domaine} from '../../../shared/components/models/domaine';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {
  maliEntreprises: Entreprise[] = [];
  ivoireEntreprises: Entreprise[] = [];
  senegalEntreprises: Entreprise[] = [];
  burkinaEntreprises: Entreprise[] = [];
  nigerEntreprises: Entreprise[] = [];
  togoEntreprises: Entreprise[] = [];
  beninEntreprises: Entreprise[] = [];
  bissauEntreprises: Entreprise[] = [];
  constructor(private router: Router,
    private meta: Meta,
    private title: Title,
              private entrepriseService: EntrepriseService) {
  }
  ngOnInit() {
    this.loadEntrepriseByCountry(221);
    this.loadEntrepriseByCountry(223);
    this.loadEntrepriseByCountry(225);
    this.loadEntrepriseByCountry(226);
    this.loadEntrepriseByCountry(227);
    this.loadEntrepriseByCountry(228);
    this.loadEntrepriseByCountry(229);
    this.loadEntrepriseByCountry(245);
    window.scrollTo(0, 0);
    this.title.setTitle("Mining Companies | WAEMU Mining Area | MineInsiders");
    this.meta.updateTag({ 
      name: 'description',
      content: 'Looking for Mining Companies? Check out MineInsiders, a digital platform dedicated to mining, to get insights on multiple mining companies. Click to Learn More!'
    });
    this.meta.updateTag({ 
      name: 'og:title',
      content: 'Mining Companies | WAEMU Mining Area | MineInsiders'
    });
    this.meta.updateTag({ 
      name: 'og:description',
      content: 'Looking for Mining Companies? Check out MineInsiders, a digital platform dedicated to mining, to get insights on multiple mining companies. Click to Learn More!'
    });
  }
  
  goToEntrepriseList(code) {
    this.router.navigate(['/repertoire'],{ queryParams: {pays: code}});
  }
  loadEntrepriseByCountry(code) {
    this.entrepriseService.findEntrepriseByCountry(code).subscribe(ret => {
      if (ret['status'] === 'OK') {
        switch (code) {
          case 223 :
            this.maliEntreprises = ret['response'];
            // console.log('maliEntreprises', this.maliEntreprises);
            break;
          case 221 :
            this.senegalEntreprises = ret['response'];
            // console.log('senegalEntreprises', this.senegalEntreprises);
            break;
          case 225 :
            this.ivoireEntreprises = ret['response'];
            // console.log('ivoireEntreprises', this.ivoireEntreprises);
            break;
          case 226 :
            this.burkinaEntreprises = ret['response'];
            // console.log('burkinaEntreprises', this.burkinaEntreprises);
            break;
          case 227 :
            this.nigerEntreprises = ret['response'];
            // console.log('nigerEntreprises', this.nigerEntreprises);
            break;
          case 228 :
            this.togoEntreprises = ret['response'];
            // console.log('togoEntreprises', this.togoEntreprises);
            break;
          case 229 :
            this.beninEntreprises = ret['response'];
            // console.log('beninEntreprises', this.beninEntreprises);
            break;
          case 245 :
            this.bissauEntreprises = ret['response'];
            // console.log('bissauEntreprises', this.bissauEntreprises);
            break;
          default:
            break;
        }
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('probleme de connexion au server');
    });
  }
}
