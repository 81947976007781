import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AccueilComponent } from './pages/accueil/accueil.component';
import { AboutComponent } from './pages/about/about.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { DialogsComponent } from './pages/dialogs/dialogs.component';
import { DocumentsComponent } from './pages/mining-documents/documents/documents.component';
import { ExpertsComponent } from './pages/miner/experts/experts.component';
import { PartenairesComponent } from './pages/partenaires/partenaires.component';
import { ArticlesComponent } from './pages/mining-news/articles.component';
import { ErrorComponent } from './pages/error/error.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/components/modules/material.module';
import { ToastrModule } from 'ngx-toastr';
import {NgxbootstrapModule} from './shared/components/modules/ngxbootstrap.module';
import {BsModalService } from 'ngx-bootstrap/modal';
// import { MyMatDialogConfig } from './configs/mat-dialog-config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CommonModule, registerLocaleData} from '@angular/common';
import { RessourcesComponent } from './pages/ressources/ressources.component';
import { JobsComponent } from './pages/opportunite/jobs/jobs.component';
import { PubliciteComponent } from './pages/publicite/publicite.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import { SitesComponent } from './pages/entreprise-miniere/entreprise/sites.component';
import { RepertoireComponent } from './pages/entreprise-miniere/repertoire-entreprise/repertoire.component';
import { ProfilComponent, PasswordDialogComponent } from './pages/profil/profil.component';
import {HttpClientModule} from '@angular/common/http';
import { ConsultantsComponent } from './pages/miner/consultants/consultants.component';
import { FormateursComponent } from './pages/miner/formateurs/formateurs.component';
import { PropositionStageComponent } from './pages/miner/proposition-stage/proposition-stage.component';
import { MinierDetailsComponent } from './pages/miner/minier-details/minier-details.component';
import { SendMessageDialogComponent } from './shared/components/send-message-dialog/send-message-dialog.component';
import { ForumComponent } from './pages/mining-documents/forum/forum.component';
import { ForumDetailComponent } from './pages/mining-documents/forum-detail/forum-detail.component';
import { SendCvDialogComponent } from './shared/components/send-cv-dialog/send-cv-dialog.component';
import { GuideComponent } from './pages/mining-documents/guide/guide.component';
import { CodeMinierComponent } from './pages/mining-documents/code-minier/code-minier.component';
import { StagesComponent } from './pages/opportunite/stages/stages.component';
import { DetailOpportuniteComponent } from './pages/opportunite/detail-opportunite/detail-opportunite.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {FilterPipeModule} from 'ngx-filter-pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import { LOCALE_ID } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { EntrepriseDetailsComponent } from './pages/entreprise-miniere/entreprise-details/entreprise-details.component';
import { RessourcesDetailsComponent } from './pages/ressources/ressources-details/ressources-details.component';
import { DocumentDetailComponent } from './pages/mining-documents/document-detail/document-detail.component';
import { GuideDetailComponent } from './pages/mining-documents/guide-detail/guide-detail.component';
import { JobsDetailComponent } from './pages/opportunite/jobs-detail /jobs-detail.component';
import { StagesDetailComponent } from './pages/opportunite/stages-detail/stages-detail.component';
import { ConditionGeneraleComponent } from './pages/condition-generale/condition-generale.component';
import { MentionLegaleComponent } from './pages/mention-legale/mention-legale.component';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import {MatCommonModule} from '@angular/material/core';
import {NgxEditorModule} from 'ngx-editor';
import {LOADING_BAR_CONFIG, LoadingBarModule} from '@ngx-loading-bar/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
registerLocaleData(localeFr);
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { MiningEventsComponent } from './pages/mining-events/mining-events.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { PubAComponent } from './pages/pub-components/pub-a/pub-a.component';
import { PubBComponent } from './pages/pub-components/pub-b/pub-b.component';
import { PubCComponent } from './pages/pub-components/pub-c/pub-c.component';
import { PubDComponent } from './pages/pub-components/pub-d/pub-d.component';
import { PubEComponent } from './pages/pub-components/pub-e/pub-e.component';
import { PubFComponent } from './pages/pub-components/pub-f/pub-f.component';
import { PubGComponent } from './pages/pub-components/pub-g/pub-g.component';
import { ArticleRecentsComponent } from './shared/components/article-recents/article-recents.component';
import { QuiSommesNousComponent } from './shared/components/qui-sommes-nous/qui-sommes-nous.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    AccueilComponent,
    AboutComponent,
    ConditionGeneraleComponent,
    MentionLegaleComponent,
    LoginComponent,
    RegisterComponent,
    DialogsComponent,
    DocumentsComponent,
    ExpertsComponent,
    PartenairesComponent,
    ArticlesComponent,
    ErrorComponent,
    ContactComponent,
    RessourcesComponent,
    JobsComponent,
    PubliciteComponent,
    SitesComponent,
    RepertoireComponent,
    ProfilComponent,
    ConsultantsComponent,
    FormateursComponent,
    PropositionStageComponent,
    MinierDetailsComponent,
    ForumComponent,
    ForumDetailComponent,
    DocumentDetailComponent,
    GuideDetailComponent,
    StagesDetailComponent,
    JobsDetailComponent,
    GuideComponent,
    CodeMinierComponent,
    StagesComponent,
    DetailOpportuniteComponent,
    EntrepriseDetailsComponent,
    RessourcesDetailsComponent,
    PasswordDialogComponent,
    SendCvDialogComponent,
    SendMessageDialogComponent,
    MiningEventsComponent,
    SearchResultComponent,
    PubAComponent,
    PubBComponent,
    PubCComponent,
    PubDComponent,
    PubEComponent,
    PubFComponent,
    PubGComponent,
    ArticleRecentsComponent,
    QuiSommesNousComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatCommonModule,
    // MyMatDialogConfig,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    FilterPipeModule,
    NgxPaginationModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    NgxEditorModule,
    HttpClientModule,
    NgxbootstrapModule,
    PdfViewerModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#959698',
      secondaryColour: '#007cdc',
      tertiaryColour: '#fff',
      fullScreenBackdrop: true,
    }),
    LoadingBarModule,
    ShareButtonsModule,
    ShareIconsModule, // Optional if you want the default share icons,
    CarouselModule,
    NgxYoutubePlayerModule.forRoot()
  ],
  entryComponents: [
    SendMessageDialogComponent,
    PasswordDialogComponent,
    SendCvDialogComponent
  ],
  providers: [BsModalService,
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
