import { Component, OnInit,AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Meta,Title } from "@angular/platform-browser";
import {NewsService} from '../../core/services/news.service';
import {News} from '../../shared/components/models/news';
import {environment} from '../../../environments/environment';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {OpportuniteService} from '../../core/services/opportunite.service';
import {Opportunite} from '../../shared/components/models/opportunite';
import {Publicite} from '../../shared/components/models/publicite';
@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit,AfterViewInit {
  list: boolean = true;
  detail: boolean = false;
  loading: boolean = false;
  newses: News[];
  jobList: Opportunite[] = [];
  news: News;
  newsImageUri = '';
  p: number = 1;
  publicites: Publicite[] = [];
  publiciteImageUri: string;
  otherNews: News[] = [];
  constructor(private router: Router,
              private meta: Meta,
              private title: Title,
              private route: ActivatedRoute,
              private newsService: NewsService,
              private loadingBar: LoadingBarService,
              private opportuniteService: OpportuniteService) {
    this.newsImageUri = environment.newsImageUri;
    this.publiciteImageUri = environment.publiciteImageUri;
  }
  renderExternalScript() {
    const body = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.innerHTML = '{ "@context": "https://schema.org", "@type": "NewsArticle", "mainEntityOfPage": { "@type": "WebPage", "@id": "https://mineinsiders.com/Jobs-detail?opportunite=172" }, "headline": "Le canadien Orezone veut lever 12 millions $ à investir dans l’or au Burkina Faso", "description": "Are you looking for a mining production superintendent position and want to join an innovative and stimulating environment within the Department of Mines?", "image": "https://mineinsiders.com/mineinsiders-service/publiciteImage/1.png", "author": { "@type": "Person", "name": "" }, "publisher": { "@type": "Organization", "name": "Emiliano Tossou", "logo": { "@type": "ImageObject", "url": "https://mineinsiders.com/mineinsiders-service/newsImage/1057.jpeg" } }, "datePublished": "2023-02-27" }';
    script.type = 'application/ld+json';
    script.async = false;
    script.defer = false;
    body.append(script);
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.news) {
        this.loadingBar.start();
        this.newsService.findNewsById(params.news).subscribe(ret => {
          if (ret['status'] === 'OK') {
            this.news = ret['response'];
            // console.log('news', this.news);
            this.loadingBar.complete();
            this.loadOthersNews(params.news);
          } else {
            this.loadingBar.complete();
            this.router.navigate(['/error']);
          }
        }, error => {
          // console.log('erreur server');
          this.loadingBar.complete();
          this.router.navigate(['/error']);
        });
      } else {
        this.loadNews();
        this.news = null;
      }
      this.title.setTitle("Mining News | Latest Mining Insights | MineInsiders");
      this.meta.updateTag({ 
        name: 'description',
        content: 'New to Mining? Check out MineInsiders, a digital platform dedicated to mining, for the latest mining news and insights on daily events. Click to Learn More!'
      });
      this.meta.updateTag({ 
        name: 'og:title',
        content: 'Mining News | Latest Mining Insights | MineInsiders'
      });
      this.meta.updateTag({ 
        name: 'og:description',
        content: 'New to Mining? Check out MineInsiders, a digital platform dedicated to mining, for the latest mining news and insights on daily events. Click to Learn More!'
      });
    });
    window.scrollTo(0, 0);
  }
  showDetail(news: News) {
    this.router.navigate(['/mining-news'] ,{ queryParams: {news: news.id} }).
    then(() => {
      window.location.reload();
    });
  }
  showList() {
    this.news = null;
    this.router.navigate(['/mining-news']);
  }
  loadNews() {
    this.loadingBar.start();
    this.newsService.findAllNews().subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.newses = ret['response'];
        this.loadingBar.complete();
      } else {
        // console.log('erreur survenu');
        this.loadingBar.complete();
      }
    }, error => {
      // console.log('erreur server');
      this.loadingBar.complete();
    });
  }

  loadOthersNews(newsId) {
    this.newsService.findOthers(newsId).subscribe(ret => {
      if (ret['status'] === 'OK') {
        this.otherNews = ret['response'];
      } else {
        // console.log('erreur survenu');
      }
    }, error => {
      // console.log('erreur server');
    });
  }
  ngAfterViewInit() {
    this.renderExternalScript();
  }
}
